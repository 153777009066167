/* speak.css */

.speak-container {
    display: flex;
    height: 100vh;
}

.speak-header {
    background-color: rgb(239, 239, 239);
    height: 10%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.back-button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

.back-button img {
    width: 34px; /* 或者任何适合的大小 */
    height: auto;
    padding-top: 8px;
}

.speak-header .title {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
}

.speak-content {
    height: 90%;
    display: flex;
    flex-direction: column;
}

.user-message-section {
    height: 50%;
    padding: 30px;
    background: rgb(239, 239, 239);
    overflow-y: auto; /* 添加滚动条 */
}

.answer-area {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(200, 201, 209);
}

.record-instruction {
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
    font-size: 1em; /* A smaller font size for the instruction text */
    color: #888; /* A lighter color for the instruction text */
}
