.body {
    background: rgb(239, 239, 239);
}

.choices-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 5px;
}

.choice-card {
    display: flex;
    flex-direction: column; /* 改为列布局，反转顺序，使图标在下方 */
    align-items: center; /* 使子项在卡片内居中对齐 */
    width: 170px;
    height: 255px;
    text-align: center; /* 让卡片内容居中显示 */
}

/*.choice-card .ant-card-body {*/
/*    padding: 16px; !* 根据需要调整padding *!*/
/*}*/

.ant-card {
    border-radius: 25px;
    padding: 50px 0px;
    background-color: rgb(255, 255, 255);
    font-size:18px
}
.choice-card img {
    width: 80px; /* 设置图片宽度 */
    height: 80px; /* 设置图片高度 */
    object-fit: cover; /* 保证图片不失真 */
    margin: 30px auto 0; /* 图片在上方留出空间 */
}

