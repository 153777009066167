.center-container {
    display: flex;
    flex-direction: column; /* 垂直排列子元素 */
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    height: 100vh; /* 使容器高度等于视口高度 */
    width: 100%; /* 确保容器宽度填满父元素 */
}

/* 为了美观，可以为输入框和按钮添加一些间距 */
.center-container > * {
    margin-bottom: 100px;
}

.input-spacing {
    margin-bottom: 60px; /* 根据需要调整这个值 */
    width: 80vw; /* 默认宽度，适用于小屏幕 */
    min-width: 150px;
}

.alert-position {
    position: absolute;
    top: 0;
    left: 50%;  /* 使其水平居中 */
    transform: translateX(-50%); /* 使用 transform 对其进行微调，使其真正居中 */
    width: 100%;
    max-width: 400px;  /* 根据你的需要设置最大宽度 */
    z-index: 1000;  /* 确保提示位于其他内容之上 */
}
