/* UserMessage.css */
.user-message p {
    text-align: left;
    white-space: pre-line;
    font-size: 1.1rem;
    margin-top: 0.4rem; /* 设置上边距为0.5rem */
    margin-bottom: 0.4rem; /* 设置下边距为0.5rem */
    line-height: 1.8rem;
}



.message-loading {
    display: none; /* 默认隐藏，通过 JavaScript 控制显示 */
    position: absolute; /* 为.message-loading添加绝对定位 */
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center; /* 水平居中 for Flexbox */
    align-items: center; /* 垂直居中 for Flexbox */
}

.system-replying {
    text-align: center; /* 文本居中显示 */
    color: #757575; /* 颜色设置为灰色 */
    font-size: 0.85rem; /* 字体大小调整 */
    margin-top: 20px; /* 距离上方的间距 */
}
/* message-loading 放在 user-message 内部 */
/* 所以我们在这里将内部的cssload-loader-inner从绝对定位改为相对定位 */
.cssload-loader-inner {
    position: relative; /* 使得.loader-inner相对于其父元素.message-loading进行定位 */
    margin: auto; /* 水平和垂直居中 */
    width: 200px;
    height: 120px;
}

.cssload-cssload-loader-line-wrap-wrap {
    animation: cssload-spin 5900ms cubic-bezier(.175, .885, .32, 1.275) infinite;
    -o-animation: cssload-spin 5900ms cubic-bezier(.175, .885, .32, 1.275) infinite;
    -ms-animation: cssload-spin 5900ms cubic-bezier(.175, .885, .32, 1.275) infinite;
    -webkit-animation: cssload-spin 5900ms cubic-bezier(.175, .885, .32, 1.275) infinite;
    -moz-animation: cssload-spin 5900ms cubic-bezier(.175, .885, .32, 1.275) infinite;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 100px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    width: 200px;
}
.cssload-loader-line-wrap {
    border: 8px solid transparent;
    border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 200px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
}
.cssload-cssload-loader-line-wrap-wrap:nth-child(1) { animation-delay: -147.5ms;
    -o-animation-delay: -147.5ms;
    -ms-animation-delay: -147.5ms;
    -webkit-animation-delay: -147.5ms;
    -moz-animation-delay: -147.5ms; }
.cssload-cssload-loader-line-wrap-wrap:nth-child(2) { animation-delay: -295ms;
    -o-animation-delay: -295ms;
    -ms-animation-delay: -295ms;
    -webkit-animation-delay: -295ms;
    -moz-animation-delay: -295ms; }
.cssload-cssload-loader-line-wrap-wrap:nth-child(3) { animation-delay: -442.5ms;
    -o-animation-delay: -442.5ms;
    -ms-animation-delay: -442.5ms;
    -webkit-animation-delay: -442.5ms;
    -moz-animation-delay: -442.5ms; }
.cssload-cssload-loader-line-wrap-wrap:nth-child(4) { animation-delay: -590ms;
    -o-animation-delay: -590ms;
    -ms-animation-delay: -590ms;
    -webkit-animation-delay: -590ms;
    -moz-animation-delay: -590ms; }
.cssload-cssload-loader-line-wrap-wrap:nth-child(5) { animation-delay: -737.5ms;
    -o-animation-delay: -737.5ms;
    -ms-animation-delay: -737.5ms;
    -webkit-animation-delay: -737.5ms;
    -moz-animation-delay: -737.5ms; }

.cssload-cssload-loader-line-wrap-wrap:nth-child(1) .cssload-loader-line-wrap {
    border-color: rgb(234,71,71);
    height: 180px;
    width: 180px;
    top: 14px;
}
.cssload-cssload-loader-line-wrap-wrap:nth-child(2) .cssload-loader-line-wrap {
    border-color: rgb(234,234,71);
    height: 152px;
    width: 152px;
    top: 28px;
}
.cssload-cssload-loader-line-wrap-wrap:nth-child(3) .cssload-loader-line-wrap {
    border-color: rgb(71,234,71);
    height: 124px;
    width: 124px;
    top: 42px;
}
.cssload-cssload-loader-line-wrap-wrap:nth-child(4) .cssload-loader-line-wrap {
    border-color: rgb(71,234,234);
    height: 96px;
    width: 96px;
    top: 56px;
}
.cssload-cssload-loader-line-wrap-wrap:nth-child(5) .cssload-loader-line-wrap {
    border-color: rgb(71,71,234);
    height: 68px;
    width: 68px;
    top: 70px;
}




@keyframes cssload-spin {
    0%, 15% {
        transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-spin {
    0%, 15% {
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-spin {
    0%, 15% {
        -ms-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-spin {
    0%, 15% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-spin {
    0%, 15% {
        -moz-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
