.info-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.info-form-container h2 {
    margin-bottom: 60px;  /* 调整为更大的间距 */
    text-align: center;
}

.info-form-container .ant-input {
    width: 30vw;
    margin-bottom: 60px;  /* 从20px调整为40px */
}

.info-form-container .ant-select {
    width: 30vw;
    margin-bottom: 60px;  /* 从20px调整为40px */
    display: flex;
    align-items: center;
}

.info-form-container .ant-select:before {
    content: "选择性别: ";
    margin-right: 10px;
}

.info-form-container .ant-btn {
    width: 30vw;
}
