.nav-container {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: white;
}

.nav-icon-container, .nav-circle-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav-icon {
    width: 25px;
    height: 25px;
}

.nav-circle-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}


.nav-icon-container span, .nav-circle-icon-container span {
    font-size: 12px;  /* 调整底部文字的大小 */
    color: #666;      /* 调整底部文字的颜色 */
}

.nav-icon-active {
    color: #FFFFFF;
}
