body {
    background-color: rgb(239, 239, 239);
}

.input-container.hidden {
    display: none !important;
}

/* 主容器样式 */
.chatbox-container {
    width: 100%;
    height: 60%; /* 占据屏幕的80%高度 */
    position: fixed; /* 使用fixed定位使其总是在屏幕的相同位置 */
    top: 0; /* 从屏幕的顶部开始 */
    left: 0; /* 从屏幕的左边开始 */
    z-index: 999;   /* 为了确保它始终在其他元素的下方 */
    overflow-y: auto; /* 如果内容超过高度则可以滚动 */
}


/* 消息容器样式 */
.messages-container {
    font-size: 20px;  /* 设置字体大小为16px */
    width: 100%;
    height: calc(95% - 60px);
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
}

/* 公共消息样式 */
.message {
    width: 60%;       /* 消息长度为屏幕的60% */
    line-height: 1.8;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    white-space: pre-wrap; /* 使 '\n' 被识别为换行 */
}

/* 您的消息样式 */
.message.me {
    width: 40%;  /* 设置您的消息的宽度为80% */
    float: right;     /* 您的消息框靠右 */
    margin-right: 20%;  /* 为您的消息左侧留出10%的空白 */
    background-color: rgb(230, 220, 198); /* 设置您的消息的背景颜色 */
    border-radius: 15px;  /* 增加圆角效果，你可以根据需要调整这个值 */
    text-align: left; /* 您的消息文字靠右对齐 */

}

/* 对方的消息样式 */
.message.them {
    float: left;      /* 对方的消息框靠左 */
    text-align: left;  /* 对方的消息文字靠左对齐 */
    margin-left: 20%;  /* 为对方的消息右侧留出10%的空白 */
    border: none;  /* 去掉对方的消息框 */
    background-color: transparent; /* 使对方的消息背景透明 */
    padding-left: 15px; /* 调整此值以改变左侧对话框的文本偏移量 */
    text-indent: 0; /* 确保文本没有首行缩进 */
}


/* 输入区域样式 */
.input-container {
    display: flex; /* 使子元素成为flex项 */
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    position: fixed; /* 使用fixed定位使其总是浮动在屏幕的相同位置 */
    bottom: 10%;     /* 距离底部10% */
    left: 20%;       /* 这使得容器在中心，因为宽度是60% */
    z-index: 1000;   /* 为了确保它始终在其他元素的顶部 */
    height: 40vh;    /* 设置为屏幕高度的30% */
}

.input-container textarea {
    border-radius: 30px;  /* 为输入框添加圆角 */
    flex-grow: 1;    /* 使输入框占据最大空间 */
    height: 90%;     /* 将高度设置为输入容器的90%以保留一些空间 */
    padding: 10px;
    font-size: 20px;
    box-sizing: border-box;
    margin-right: 10px; /* 在输入框和按钮之间添加间距 */
    resize: none;       /* 防止用户调整大小 */
    background-color: rgb(248, 243, 229);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);  /* 下方的阴影效果 */
    transition: box-shadow 0.3s ease;  /* 平滑的过渡效果 */
    border-color: transparent;
    padding-left: 15px; /* 确保此值与.message.them的左侧padding相同 */
    caret-color: green; /* 设置光标颜色为绿色 */
}

.input-container textarea:focus {
    background-color: rgb(248, 243, 229);
    outline: none;
    box-shadow: none;
}

.input-container button {
    height: 90%;    /* 与输入框相同，这样它们将是相同的高度 */
    background-color: #4CAF50;
    border: none;
    padding: 0 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}


.messages-container.faded .message {
    opacity: 0.4;
}

.messages-container.faded .message.them:last-of-type {
    opacity: 1;
}

.input-container button:hover {
    background-color: #45a049;
}

.char-counter {
    position: absolute;     /* 使用绝对定位将字符计数放置在textarea的右下角 */
    bottom: 5px;            /* 与textarea底部保持5px的距离 */
    right: 5px;             /* 与textarea右侧保持5px的距离 */
    font-size: 15px;        /* 字符计数的字体大小 */
    font-weight: bold;
    color: #888;            /* 字符计数的颜色 */
}

.voice-version-btn {
    position: absolute;
    top: 10px; /* You can adjust this value as needed */
    right: 10px; /* You can adjust this value as needed */
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    z-index: 10; /* Make sure it is above any other content */
}
/*.send-btn {*/
/*    position: absolute;*/
/*    bottom: 30px; !* 这样可以确保按钮的一部分略微进入输入框内部，您可以根据需要进行调整 *!*/
/*    right: 5%;  !* 与输入框的右边界保持一定距离 *!*/
/*    width: 20px; !* 设置为输入框的20%半径，所以宽度和高度为40% *!*/
/*    height: 20px; !* 设置为输入框的20%半径，所以宽度和高度为40% *!*/
/*    border-radius: 50%; !* 使按钮成为圆形 *!*/
/*    background-color: rgb(248, 243, 229); !* 默认颜色与页面背景颜色相同 *!*/
/*    border: none;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center; !* 以上三行使SVG图标居中 *!*/
/*    cursor: pointer;*/
/*    transition: background-color 0.3s; !* 渐变效果 *!*/
/*}*/

/*.send-btn.has-text {*/
/*    background-color: rgb(53, 122, 36);*/
/*}*/

/*.send-btn svg {*/
/*    fill: white;*/
/*    width: 60%;  !* 根据按钮大小适当缩小SVG图标的大小 *!*/
/*    height: 60%; !* 根据按钮大小适当缩小SVG图标的大小 *!*/
/*}*/


