@keyframes pulse-ring {
    0% {
        transform: scale(1);
        opacity: 0.2;
    }
    100% {
        transform: scale(2.2); /* 或者根据扩散大小调整scale的值 */
        opacity: 0.1;
    }
}


@keyframes countdown {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 314; /* Assuming the circumference of the circle to be 283 */
    }
}

.audio-recorder {
    display: flex; /* Set the container to flex */
    justify-content: center; /* Center the children horizontally */
    align-items: center; /* Center the children vertically */
    position: relative; /* Position relative to allow absolute children positioning */
    padding-bottom: 50px;
}

.record-button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    display: flex; /* 按钮内容居中 */
    justify-content: center;
    align-items: center;
}


.icon-wrapper {
    border: 40px solid white;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center; /* Center the icon vertically */
    cursor: pointer;
}

.icon-wrapper::after {
    content: '';
    position: absolute;
    background: transparent;
    border-radius: 50%;
    border: 40px solid white;
    animation: pulse-ring 2s infinite ease-in-out; /* 应用动画 */
}

.icon-wrapper img {
    display: block; /* 防止图片下方出现空隙 */
    width: 30px;    /* 或者根据需求设置合适的大小 */
    height: auto;
    background-color: white;
}
/*  下面是倒计时   */

.countdown-ring {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25); /* 添加阴影效果 */
    border-radius: 50%; /* 确保是圆形 */
    background: radial-gradient(circle at center, #ffffff 0%, #f0f0f0 80%, #e0e0e0 100%); /* 添加径向渐变效果 */
    cursor: pointer; /* 将光标变成指针，增加可点击的提示 */
    transition: all 0.2s ease-in-out; /* 添加过渡效果，使点击时有动态反馈 */

    display: none;
    margin: auto; /* Auto margins for perfect center alignment */
    width: 110px; /* Same width as icon-wrapper for proper overlay */
    height: 110px; /* Same height as icon-wrapper for proper overlay */
    justify-content: center; /* Align inner items horizontally center */
    align-items: center; /* Align inner items vertically center */
}


.countdown-ring:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); /* 点击时调整阴影大小，给人按下的错觉 */
    transform: translateY(3px); /* 点击时轻微下移，增强凸起按钮的效果 */
}

/* SVG circle style for the countdown animation */
.countdown-circle {
    transform: rotate(-90deg); /* Added this line to start from the top */
    fill: none;
    stroke: rgb(171, 193, 148);
    stroke-width: 10;
    r:50;
    stroke-dasharray: 314; /* The new circumference of the circle */
    stroke-dashoffset: 314; /* Start with a full circle */
    transform-origin: center;
    animation: countdown 58s linear backwards; /* 60 second countdown */
}
